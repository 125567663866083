/* default.css */

body.extended {
    min-height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
}

footer {
    background: #00716c;
    color: #fff;
    text-align: right;
    font-weight: bold;
    padding: 20px;
    left: 0;
    bottom: 0;
    width: 100%;
    grid-row-start: 2;
    grid-row-end: 3;
}

footer.admin {
    background: #00716c;
    color: #fff;
    text-align: right;
    font-weight: bold;
    padding: 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.date {
    color: #555;
    width: 100px;
}

.date-show {
    color: #555;
    float: right;
}

.title a {
    font-size: 20px;
    font-weight: bold;
    color: #666;
}

.title a:hover {
    color: #00716c;
    text-decoration: none;
}

.text-muted {
    color: #777;
    text-align: center;
}

.text-muted + p {
    color: #777;
    text-align: center;
}

.link-hover {
    font-weight: bold;
}

.link-hover:hover {
    color: #222;
    font-weight: bold;
    text-decoration: none;
}

/* border-radius all button & input field */
* {
    border-radius: 0 !important;
}

hr.h1 {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #aaa;
    margin: 1em 0;
    padding: 0;
}

h1 {
    color: #00716c;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
}

.pagination li a {
    color: #00716c;
}

.pagination > .active > a {
    background-color: #00716c;
}

.pagination > .active > a:hover {
    background-color: #00716c;
}

.btn-success {
    background: #00716c;
    border: 1px solid #fff;
}

.btn-success:hover {
    color: #ccc;
    background: #00716c;
}

label {
    margin-top: 20px;
}

.edit_form_buttons {
    float: right;
}

.form_footer {
    margin-top: 40px;
}

.login-form {
    padding: 20px;
}

.login-input {
    width: 200px;
    margin: 20px;
}

.login-submit {
    margin-left: 35px;
}

.login-center {
    margin: auto;
    width: 100%;
}

.login-center-width {
    margin: auto;
    width: 290px;
}

.fontAwesome {
    font-family: 'Helvetica', FontAwesome, sans-serif;
}

.color {
    color: #00716c;
}

.error-span {
    color: #FF0000;
    margin-bottom: 30px;
    white-space: pre;
    height: 130px;
}

.padding-div {
    height: 20px;
}

.padding-2div {
    height: 40px;
}

.prolugue {
    font-size: 16px;
}

hr.show {
    border-color: #00716c;
    border-width: 3px;
    max-width: 50px;
    text-align: center;
    width: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

hr.light {
    border-color: white;
}

section.extended {
    padding: 50px 0;
}

@media (min-width: 767px) {
    section.extended {
        padding: 100px 0;
    }
}

.aligncenter {
    text-align: center;
}

.box {
    width: 230px;
    display: inline-block;
}

.box-dev {
    max-width: 400px;
    display: inline-block;
}

.box-center {
    padding: 20px;
    text-align: center;
    width: auto;
}

.search-button {
    border: 1px solid #00716ce6;
    width: 100px;
}

#homeHeading {
    color: white;
    border: none;
}

.header-content-inner p {
    font-weight: bold !important;
    color: white !important;
}

.portfolio-box .portfolio-box-caption {
    color: #fff;
    opacity: 0;
    display: block;
    background: #00716ce6;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    transition: all .35s;
}

.spacer {
    height: 500px;
}

.text-faded + p {
    color: rgba(255, 255, 255, .7);
}

header .header-content .header-content-inner p {
    font-weight: 300;
    color: rgba(255, 255, 255, .7) !important;
    font-size: 16px;
    margin-bottom: 50px;
}

header .header-content .header-content-inner p + p {
    font-weight: 300;
    color: rgba(255, 255, 255, .7) !important;
    font-size: 16px;
    margin-bottom: 50px;
}

.navbar-margin {
    margin-left: 20px !important;
}

@media only screen and (max-width: 768px) {
    .image-subtitle {
        position: absolute !important;
        width: 290px !important;
        height: 60px !important;
        bottom: 0 !important;
        left: 0 !important;
        color: lightgrey !important;
        background: #00716ce6 !important;
        text-align: center !important;
        font-weight: bold !important;
        opacity: 0.7 !important;
    }

    .image-size {
        width: 290px;
    }

    .head-line{
        font-size: 30px;
    }
}

@media only screen and (min-width: 768px) {

    .image-subtitle {
        width: 380px !important;
    }

    .image-size {
        width: 380px;
        height:285px;
    }

    .head-line{
        font-size: 36px;
    }
}

.gallery-center{
    display:table !important;
}

.search-row {
    display: inline-table;
    vertical-align: middle;
}

.search-row-button {
    display: table-cell;
    width: 1%;
}

.btn-default:hover {
    background: #222;
    color: #ddd;
}

.image-show-small {
    width: 200px;
    border: 1px solid #000;
    padding: 5px;
}

.img-development {
    max-width: 320px;
    height: 215px;
    text-align: center;
}

.img-dev-missing {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 156px;
}

.header-logo {
    width: 20px;
    margin: 0;
    margin-right: 5px;
    padding: 0;
    float: left;
}