//Global Styles
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: avenir, sans-serif;
  height: 100%
}

//Variables
$nav-width: 225px;

//Icon for opening sidenav
.hamburger {
  color: #00716c;
  margin-top: 0px;
  position: fixed;
  z-index: 9999;
  padding: 15px;

  &:hover {
    cursor: pointer;
  }

  div {
    background-color: white;
    border-radius: 1px;
    height: 6px;
    width: 30px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

//Sidenav & its contents
.leftmenu {
  background-color: #00716c;
  display: inline-block;
  position: fixed;
  height: 100%;
  width: $nav-width;
  left: -$nav-width;
  transition: transform 0.3s;

  .logo {
    margin: 20px;
    margin-top:70px;
    text-align: center;
    color:white;
    font-size:14px;
    font-weight: bolder;

    p {
      font-weight: 100;
      font-size: 1em;
    }

    img {
      border-radius: 50%;
    }
  }

  a {
    display: block;
    text-decoration: none;
    text-align: left;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;

    &:hover {
      background-color: #f5f5f5;
      color: black;
    }

    &.active {
      background-color: black;
      color: white;
    }
  }
  a > i {
   padding-right: 10px;
  }

}

//When sidenav is open:
//1. Nudge everything to the side
.leftmenu, .hamburger, .cover {
  transition: transform .3s;

  .sidenav-active & {
    transform: translateX($nav-width);
  }
}

//2. Shrink main to fit nav on screen
.main {
  transition: margin-left .3s;

  .sidenav-active & {
    margin-left: $nav-width;
  }
}

.menu-header{
  background:rgb(0, 92, 88);
}